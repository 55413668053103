@import '../../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.list-container .checkbox > label {
  display: block;
}

.filter-item {
  display: -ms-grid;
  display: grid;
  -ms-grid-rows: auto;
  grid-template-rows: auto;
  -ms-grid-columns: 1fr auto;
  grid-template-columns: 1fr auto;

  .filter-item-content {
    display:inline-block;
    -ms-grid-row:1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
    overflow: hidden;
    text-overflow: ellipsis;
    -ms-white-space: normal;
    white-space: nowrap;
  }

  .filter-item-count {
    display:inline-block;
    -ms-grid-row: 1;
    grid-row-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column:2;
    grid-column-start: 2;
    -ms-grid-column-span: 1;
    grid-column-end: span 1;
  }
}
