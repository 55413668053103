@import '../../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.searchable-selector .rbt-input-wrapper .rbt-token{
  max-width: 100%;

  .searchable-selector-pill-text {
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 100%;
  }
}


