@import '../../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.column-sort {
  white-space: nowrap;
  display: block;
  min-width: 1px;

  > .column-sort-title {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    min-width: 1px;
  }
}

.column-sorter {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 12px;
  text-align: center;
  font-weight: 400;

  .btn-link:hover,
  .btn-link:focus,
  .btn:active:focus {
    text-decoration: none;
    outline: none;
  }

  .vehicle-views-column-sorter-up {
    position: absolute;
    bottom: 50%;
    width: 14px;
    height: 16px;
    display: block;
    color: #cacfd9;

    svg {
      position: absolute;
      left: 0;
      top: -4px;
      cursor: pointer;
    }
  }

  .vehicle-views-column-sorter-down {
    position: absolute;
    top: 25%;
    width: 14px;
    height: 16px;
    display: block;
    cursor: pointer;
    color: #cacfd9;

    svg {
      position: absolute;
      left: 0;
      bottom: -4px;
      cursor: pointer;
    }
  }

  .vehicle-views-column-sorter-down.active {
    color: #f00;
  }
}
