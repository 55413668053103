@import '../../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.card.gray {
  background: #f5f5f5;
}

.card.borderless {
  border: none;

  &.panel {
    box-shadow: none;
  }
}

.card.panel {
  margin-bottom: 0;
}

.card.offset {
  .details-card-body {
    margin-left: 32px;
  }
  .details-card-title {
    min-height: 32px;
  }
}
