@import '../../../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.title-card-button {
  float: right;
}

.title-card-header {
  float: left;
  margin: 0 6px;
}
