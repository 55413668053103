@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.ant-table-body .ant-table-tbody .selected-row td {
  background: #ceedfe;
}

.ant-table-scroll .ant-table-body {
  // important for css-grid
  min-width: 0;
  min-height: 0;
}

.cx-ant-table-wrapper .ant-table {
  overflow-x: auto;
}

.ant-table-header-column .ant-table-column-sorter {
  display: none;
}

.ant-table-expand-on-row-click .ant-table-thead tr {
  cursor: default;
}

.ant-table-body .ant-table-tbody > tr.ant-table-expanded-row:hover > td {
  background: #fff;
  cursor: default;
}
