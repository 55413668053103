@import '../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

body {
  background-color: #fff !important;
}

.text-align-center {
  text-align: center;
}

.vertical-align-middle {
  vertical-align: middle;
}

table .currency {
  display: block;
  text-align: right;
}
