@import '../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.borderless .cx-accordion {
  border: none;
  margin: 0;
  box-shadow: none;
}

.divider .cx-accordion {
  border: none;
  margin: 0;
  box-shadow: none;
}

.divider .accordion-group + .accordion-group {
  border-top: 1px solid #cacfd9;
}

.filter-section-title {
  margin: 0 8px 0 0;
  display: inline-block;
}

.ezFilters {
  .form-group {
    margin-bottom: 16px;
  }

  .btn + .btn {
    margin-left: 12px;
  }

  .list-container__expand-button {
    margin: 12px 0;
  }

  .dropdown .btn + .btn {
    margin-left: -1px;
  }

  .btn + .btn-group {
    margin-left: 12px;
  }

  .badge {
    margin-left: 8px;
  }

  .list-container {
    .checkbox {
      margin: 4px 0;
    }
  }

  .filter-buttons {
    text-align: right;
  }

  .cx-accordion .cx-accordion-title > a {
    padding: 8px 0;
    font-size: 14px;
  }

  .cx-accordion-body {
    padding: 0 16px;
  }

  .addon-prefix {
    min-width: 48px;
  }
}
