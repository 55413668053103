@import '../../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.tooltip-inner-wrapper {
  display: inline-block;

  > svg {
    height: 18px;
    width: 18px;
    margin: 0;
    color: #2b6bdd;
    vertical-align: bottom;
  }
}
