@import '../../../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.pill {
  margin-right: 8px;

  .btn.pill-close-button {
    padding: 0 0 0 3px;
    margin: 0px;
    line-height: 12px;

    svg {
      height: 15px;
      width: 15px;
    }
  }
}
