@import '../../../../node_modules/@cx/theme/src/base/_cx-bootstrap-variables.scss';

.cx-accordion .hide-carat .cx-accordion-title > a {
  cursor: default;

  svg.icon-keyboard-arrow-right {
    display: none;
  }
}


